<template>
  <div>
    <section class="bg-half bg-soft-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mb-0">Política de Privacidade</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">

            <div class="card shadow rounded border-0">
              <div class="card-body">
                <p class="text-muted text-justify">A sua privacidade é importante para nós. É política da <b>StartGov</b> respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar neste site, e outros sites que possuímos e operamos.</p>

                <p class="text-muted text-justify">Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-los por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>

                <p class="text-muted text-justify">Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>

                <p class="text-muted text-justify">O nosso site pode ter links e tokens para compartilhamento de dados com sites externos, principalmente de natureza governamental, como a Plataforma + Brasil do Governo Federal, que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e banco de dados governamentais e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade. Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados. Salientamos ainda que compartilhamos dados pessoais com entes governamentais, a fim de atender à Lei de Acesso a Informação (Lei Federal nº 12.527/2011), não sendo possível a negativa do usuário da Plataforma quanto à aplicação e destinação dos dados dos processos licitatórios realizados por nós.</p>

                <p class="text-muted text-justify">O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>

                <h5>Cookies que definimos</h5>

                <h5>Cookies relacionados à conta</h5>

                <p class="text-muted text-justify">Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair. </p>

                <h5>Cookies relacionados ao login</h5>

                <p class="text-muted text-justify">Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login. </p>

                <h5>Cookies relacionados a boletins por e-mail</h5>

                <p class="text-muted text-justify">Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos/não inscritos. </p>

                <h5>Pedidos processando cookies relacionados</h5>

                <p class="text-muted text-justify">Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente. </p>

                <h5>Cookies relacionados a pesquisas</h5>

                <p class="text-muted text-justify">Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas. </p>

                <h5>Cookies relacionados a formulários</h5>

                <p class="text-muted text-justify">Quando você envia dados por meio de um formulário como os encontrados nas páginas de contato ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura. </p>

                <h5>Cookies de preferências do site</h5>

                <p class="text-muted text-justify">Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências. </p>

                <h5>Cookies de Terceiros</h5>

                <p class="text-muted text-justify">Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site. </p>

                <p class="text-muted text-justify">As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você. </p>

                <p class="text-muted text-justify">Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam. </p>

                <p class="text-muted text-justify">À medida que desenvolvemos nossa Plataforma de Licitações e ofertamos novas funcionalidades, é importante entendermos as estatísticas sobre quantos visitantes visitaram nosso site, se ele é realmente funcional, dentre outros critérios e, onde será necessário melhorar. Portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analisar nossos custos de publicidade e produtos para garantir o melhor preço possível. </p>

                <h5>Mais informações</h5>

                <p class="text-muted text-justify">Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site. </p>

                <p class="text-muted text-justify">Esta política é efetiva a partir de maio de 2023.</p>

                <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Imprimir</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },

};
</script>


